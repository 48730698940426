@import url("https://fonts.googleapis.com/css2?family=Bungee+Hairline");

* {
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100vw;
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Bungee Hairline", monospace;
  background: #000000;
}
