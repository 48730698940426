.start-screen {
  height: 100vh;
  width: 100vw;
  background-color: #000000;
  color: #00ff00;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

h1 {
  font-size: 6em;
  line-height: 64px;
  margin: 0;
}

.start {
  font-size: 2em;
  cursor: pointer;
  margin: 0;

  animation: animate 3s linear infinite;
}

footer {
  position: absolute;
  bottom: 0;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 16px;
}

.middle {
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 1.5em;
}

a {
  color: #00ff00;
}

@keyframes animate {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
