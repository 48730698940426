@import url(https://fonts.googleapis.com/css2?family=Bungee+Hairline);
* {
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100vw;
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Bungee Hairline", monospace;
  background: #000000;
}

.app {
  height: 100vh;
  width: 100vw;
  position: relative;
}

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 2000ms;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 2000ms;
}

.start-screen {
  height: 100vh;
  width: 100vw;
  background-color: #000000;
  color: #00ff00;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

h1 {
  font-size: 6em;
  line-height: 64px;
  margin: 0;
}

.start {
  font-size: 2em;
  cursor: pointer;
  margin: 0;

  -webkit-animation: animate 3s linear infinite;

          animation: animate 3s linear infinite;
}

footer {
  position: absolute;
  bottom: 0;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 16px;
}

.middle {
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 1.5em;
}

a {
  color: #00ff00;
}

@-webkit-keyframes animate {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes animate {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

